<template>
  <div class="auth">
    <div class="auth__main">
      <div class="auth__empty"></div>

      <a
          class="auth__link-logo"
          target="_blank"
      >
        <img
          src="@/assets/icons/icon.png"
          width="75"
          height="75"
        />
      </a>

      <div class="auth__empty"></div>

      <router-view/>
    </div>

    <div class="auth__footer">
      <component :is="footerNavigationComponent"/>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import AuthLanguages from '@/components/auth/AuthLanguages'
import SignInFooter from '@/components/auth/footer/SignInFooter'
import SignUpFooter from '@/components/auth/footer/SignUpFooter'
import BackToLogInFooter from '@/components/auth/footer/BackToLogInFooter'
import BackToRegistrationFooter from '@/components/auth/footer/BackToRegistrationFooter'
import Rules from '@/components/auth/footer/Rules'

export default {
  name: 'authLayout',
  components: {
    AuthLanguages,
    SignInFooter,
    SignUpFooter,
    BackToLogInFooter,
    BackToRegistrationFooter,
    Rules,
  },

  computed: {
    ...mapState('user', [
      'user',
    ]),

    ...mapGetters('general', [
      'getCurrentLangCode',
    ]),

    footerNavigationComponent () {
      const wordsOfName = this.$route.name.split('-')
      let wordsOfNameToCamelCase = wordsOfName
        .map(word => {
          return word[0].toUpperCase() + word.slice(1)
        })
        .join('')

      switch (wordsOfNameToCamelCase) {
        case 'ResetPassword':
          wordsOfNameToCamelCase = 'BackToLogIn'
          break
        case 'ChangePassword':
          wordsOfNameToCamelCase = 'BackToLogIn'
          break
        case 'ConfirmEmail':
          wordsOfNameToCamelCase = 'BackToRegistration'
          break
        case 'SocialAuth':
          wordsOfNameToCamelCase = 'BackToLogIn'
          break
      }

      return wordsOfNameToCamelCase + 'Footer'
    },
  },

  created () {
    if (this.user) {
      this.setUserData(null)
    }
  },

  methods: {
    ...mapMutations('user', {
      setUserData: 'SET_USER_DATA',
    }),
  },
}
</script>

<style lang="scss" scoped>
.auth {
  font-family: "Manrope VF", sans-serif;
  display: grid;
  grid-template-rows: 2fr 1fr;
  justify-items: center;
  height: 100%;
  background-color: #f8f8f8;
  /*background: linear-gradient(180deg, rgba(0, 123, 255, 0.15) 0%,*/
  /*    rgba(255, 255, 255, 0));*/

  @media (max-height: 875px) {
    grid-template-rows: auto 175px;
    height: 875px;
    overflow-y: auto;
  }
}

.auth__main {
  display: grid;
  grid-template-rows: minmax(20px, 1fr) max-content 40px 1fr;
  margin: 0 auto;
  width: 360px;

  @media (max-height: 950px) {
    grid-template-rows: minmax(20px, .1fr) max-content 40px 1fr;
  }
}

.auth__link-logo {
  display: flex;
  align-items: center;
  margin: 0 auto;
  height: 100%;
  width: max-content;
  filter: drop-shadow(1px 1px 1px #666);

  svg {
    height: 32px;
    width: 124px;
    transition: fill .3s ease-in-out;

    path {
      &:last-of-type {
        fill: #6788F3;
      }
    }
  }

  &:hover {
    svg {
      path {
        fill: $blue;
      }
    }
  }
}

.auth__footer {
  display: grid;
  grid-template-rows: 46px minmax(20px, 1fr) max-content minmax(20px, 1fr) max-content minmax(40px, 2fr);
  justify-items: center;
  width: 100%;
}
</style>
