import { render, staticRenderFns } from "./BackToLogInFooter.vue?vue&type=template&id=9af4ca12&scoped=true&"
import script from "./BackToLogInFooter.vue?vue&type=script&lang=js&"
export * from "./BackToLogInFooter.vue?vue&type=script&lang=js&"
import style0 from "./BackToLogInFooter.vue?vue&type=style&index=0&id=9af4ca12&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9af4ca12",
  null
  
)

export default component.exports