<template>
  <div class="rules">
    <div>
      {{ $t('By using WEEEK you acknowledge') }}
    </div>
    <div>
      {{ $t('that you have read and understood, and agree') }}
    </div>

    {{ $t('to WEEEK\'s') }}

    <a
      :href="`https://weeek.net${langLink}document/index?id=8`"
      target="_blank"
      class="rules__link"
    >
      {{ $t('Terms & Conditions') }}
    </a>

    {{ $t('and') }}

    <a
      :href="`https://weeek.net${langLink}document/index?id=24`"
      target="_blank"
      class="rules__link"
    >
      {{ $t('Privacy Policy.') }}
    </a>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Rules',
  computed: {
    ...mapState('general', [
      'language',
    ]),

    langLink () {
      const lang = this.language.split('-')[0]

      return lang === 'en'
        ? '/'
        : `/${lang}/`
    },
  },

  i18n: {
    messages: {
      'en': {
        'By using WEEEK you acknowledge': 'By using WEEEK you acknowledge',
        'that you have read and understood, and agree': 'that you have read and understood, and agree',
        'to WEEEK\'s': 'to WEEEK\'s',
        'Terms & Conditions': 'Terms & Conditions',
        'and': 'and',
        'Privacy Policy.': 'Privacy Policy.',
      },
      'ru': {
        'By using WEEEK you acknowledge': 'Используя trade-fox, Вы подтверждаете,',
        'that you have read and understood, and agree': 'что прочитали и поняли, а также соглашаетесь',
        'By using WEEEK': 'Используя WEEEK, Вы подтверждаете, что прочитали и поняли, а также соглашаетесь с',
        'to WEEEK\'s': 'с',
        'Terms & Conditions': 'Правилами & Условиями',
        'and': 'и',
        'Privacy Policy.': 'Политикой конфиденциальности.',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.rules {
  max-width: 360px;
  font-size: 12px;
  font-weight: 500;
  font-variation-settings: 'wght' 500;
  line-height: 16px;
  color: #D0D3DD;
  text-align: center;
}

.rules__link {
  text-decoration: none;
  color: #D0D3DD;
  border-bottom: 2px solid #E3E4E8;
}
</style>
