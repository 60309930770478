<template>
  <div class="reset-password-footer">
    <VLink
      to="sign-in"
      :outlined="true"
    >
      <div class="reset-password-footer__content">
        <div>
          &larr;
        </div>

        <div>
          {{ $t('Back to Log In') }}
        </div>
      </div>
    </VLink>
  </div>
</template>

<script>
import VLink from '@/components/ui/Auth/VLink'

export default {
  name: 'BackToLogInFooter',
  components: {
    VLink,
  },

  i18n: {
    messages: {
      'en': {
        "Back to Log In": "Back to Log In",
      },
      'ru': {
        "Back to Log In": "Вернуться на страницу входа",
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.reset-password-footer__content {
  display: grid;
  grid-template-columns: repeat(2, max-content);
  grid-column-gap: 4px;
}
</style>
