<template>
  <div
    ref="lang"
    class="auth-languages"
  >
    <div
      v-click-outside="hideDropdown"
      :class="['auth-languages__selected', { 'opened': openedDropdown }]"
      @click="toggleDropdown"
    >
      <component
        :is="selectedLanguage.component"
        class="auth-languages__selected-img"
      />

      <div class="auth-languages__selected-name">
        {{ selectedLanguage.name }}
      </div>
    </div>

    <div
      v-if="openedDropdown"
      :class="['auth-languages__dropdown', { bottom: !hasBottomPlace }]"
    >
      <div
        v-for="language in languages"
        :key="language.name"
        class="auth-languages__dropdown-item"
        @click="changeLang(language)"
      >
        <component
          :is="language.component"
          class="auth-languages__selected-img"
        />

        <div class="auth-languages__selected-name">
          {{ language.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { languages } from '@/i18n/languages'
import IconRu from '@/assets/icons/languages/ru.svg'
import IconEn from '@/assets/icons/languages/en.svg'

export default {
  name: 'AuthLanguages',
  components: {
    IconRu,
    IconEn,
  },

  data: () => ({
    hasBottomPlace: false,
    openedDropdown: false,
    languages: null,
    selectedLanguage: null,
  }),

  created () {
    this.languages = languages
    this.selectedLanguage = languages.find(language => language.name === this.$i18n.locale.split('-')[0])
  },
  mounted () {
    this.calcBottomPlace()
  },

  methods: {
    ...mapActions('general', [
      'setLanguage',
    ]),
    calcBottomPlace () {
      const lang = this.$refs.lang
      const langTop = lang.getBoundingClientRect().y
      const langHeight = lang.getBoundingClientRect().height

      this.hasBottomPlace = window.innerHeight > langTop + langHeight + 240
    },
    toggleDropdown () {
      this.openedDropdown = !this.openedDropdown
    },
    hideDropdown () {
      this.openedDropdown = false
    },
    changeLang (lang) {
      this.$router.replace({ query: {} })
      this.selectedLanguage = lang
      this.setLanguage(lang.code)
      this.hideDropdown()
    },
  },
}
</script>

<style lang="scss" scoped>
.auth-languages {
  position: relative;
  width: 64px;
  font-weight: 700;
  font-variation-settings: 'wght' 700;
}

.auth-languages__selected {
  display: grid;
  grid-template-columns: repeat(2, 16px);
  grid-column-gap: 16px;
  align-items: center;
  padding: 0 6px;
  height: 32px;
  border: 2px solid #E0E1E5;
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    background-color: #F4F4F6;
  }

  &:active {
    background-color: #E3E4E8;
  }
}

.opened {
  background-color: #FFF;
  box-shadow: 0 3px 8px rgba(0, 0, 0, .1);
}

.auth-languages__dropdown {
  position: absolute;
  left: 0;
  top: 100%;
  width: inherit;
  background-color: #FFF;
  border-radius: 8px;
  box-shadow: 0 6px 16px rgba(103, 136, 243, .08);

  &.bottom {
    top: auto;
    bottom: calc(100% + 6px);
  }
}

.auth-languages__dropdown-item {
  display: grid;
  grid-template-columns: repeat(2, 16px);
  grid-column-gap: 16px;
  align-items: center;
  padding: 0 6px;
  height: 32px;
  cursor: pointer;

  &:hover {
    background-color: #F4F4F6;
  }

  &:active {
    background-color: #E3E4E8;
  }

  &:first-of-type {
    border-radius: 8px 8px 0 0;
  }

  &:last-of-type {
    border-radius: 0 0 8px 8px;
  }
}

.auth-languages__selected-img {
  height: 12px;
  width: 16px;
  border-radius: 2px;
}

.auth-languages__selected-name {
  font-weight: 700;
  font-variation-settings: 'wght' 700;
  font-size: 12px;
  line-height: 16px;
  color: #7B8193;
  text-transform: uppercase;
}
</style>
